.resizable-container {
  display: flex;
  height: 300px;
}

.resizable-pane {
  flex: 1;
  min-width: 0;
  overflow: auto;
  cursor: ew-resize;
}

.left-pane {
  min-width: 5rem;
  /* Adjust as needed */
}

.right-pane {
  min-width: 5rem;
  /* Adjust as needed */
}

.splitter {
  width: 10px;
  height: 100%;
  background-color: #aaa;
  cursor: ew-resize;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
}

.splitter:hover {
  background-color: #ccc;
}


.dashboard-item {
  /* background-color: azure*/


}

p.choose {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboard-view {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.dashboard-container {
  position: absolute;
  left: 50%;
  width: 100%;
  /* top: 14%; */
  /* max-width: 100rem; */
  overflow: hidden !important;
  transform: translate(-50%, 0);
}

.dashboard-header .Mui-selected {
    background: #cf0a0a;
    color: #fff !important;
}
.video {
  width: 900px;
  position: absolute;
  top: 9%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
}

.relative {
  position: relative;
}

.mb-6 {
  margin-top: 6%;
}

.pt4 {
  padding-top: 40px;
}

h1.snheading {
  font-size: 25px;
}

#outlined-basic {
  width: 100%;
}

label {
  text-align: left;
}

.shadow {
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  /* Customize this to your liking */
  border-radius: 15px;
  /* Optional, for rounded corners */
  padding: 16px;
  /* Optional, for internal spacing */
  text-align: left;
  text-transform: capitalize;
}