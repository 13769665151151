.App {
  /* text-align: center; */
  /* background-image: url('img-3.jpg'); */
  /* Replace 'background-image.jpg' with the path to your image */
  background-size: cover;
  /* Cover the entire viewport */
  background-position: center;
  /* Center the background image */
  background-attachment: fixed;
  /* Fixed positioning */
  /* Additional styles as needed */
  height: calc(100vh - 64px);
  /* width: 100h; */
}

.App-logo {

  pointer-events: none;
}

.loan-tax {
  /* margin-top: 20px; */
  float: left;
  width: 100%;
  position: relative;
  z-index: 9999;
}

.chart-container {
  position: relative;
  width: 100%;
  height: 300px;
  /* or any height you prefer */
  margin: auto;
}

.pie-chart-container {
  position: relative;
  width: 100%;
  height: 30vh;
  /* or any height you prefer */
  margin: auto;
}

.pie-chart {
  position: absolute;
  left: 40%
}

.overlay-message {
  width: 100%;
  position: absolute;
  top: 0;
  text-align: center;
  float: left;
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.mainContainer {
  display: flex;
  justify-content: center;
  /* Center horizontally */
  align-items: center;
  /* Center vertically */
  height: 100%;
}

.searchField {
  width: 500px;
  background-color: #ffffff;
}

.comingSoon {
  color: white;
  background-color: #095dca;
  font-family: cursive;
  font-size: 80px;


  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* background-color: #f44336; */
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  z-index: 9999;
  animation: float 4s infinite alternate;
}

@keyframes float {
  0% {
    transform: translate(-50%, -50%) translateY(-80px) translateX(-80px);
  }

  100% {
    transform: translate(-50%, -50%) translateY(80px) translateX(80px);
  }
}

#target {
  position: absolute;
  background: #ffffff;
  width: 100%;
  height: 100%;
  padding: 0px;
  z-index: 9;
  padding: 0%;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, .15);
  top: 7vh;
}

.lefftt {
  width: 49%;
  float: left;
  height: 90vh;
  margin-right: 1%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.righhtt {
  width: 49%;
  float: right;
  height: 90vh;
  margin-left: 1%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

img.back {
  width: 65px;
  margin-top: 5px;
}

.cards img {
  height: 70px;
  margin-top: 5px;
  border-radius: 5px;
}

.Hide {
  display: none;
}

.navbar-default {
  background-color: #fff;
  margin-left: 500px;
}

/*main side bar*/
.msb {
  background-color: #ffffff;
  left: 0;
  top: 0;
  right: auto;
  min-height: 100%;
  z-index: 1;
  border-right: 1px solid #ddd;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 25px;
  max-height: 100vh;
  padding-bottom: 10vh;
  overflow: auto;
}

.msb .navbar {
  border: none;
  margin-left: 0;
  background-color: inherit;
}

.msb .navbar-header {
  width: 100%;
  border-bottom: 1px solid #e7e7e7;
  margin-bottom: 20px;
  background: #fff;
}

.msb .navbar-nav .panel {
  border: 0 none;
  box-shadow: none;
  margin: 0;
  background: inherit;
}

.msb .navbar-nav li {
  display: block;
  width: 100%;
}

.msb .navbar-nav li a {
  padding: 0px;
  color: #5f5f5f;
  line-height: normal;
}

.msb .navbar-nav li a .glyphicon,
.msb .navbar-nav li a .fa {
  margin-right: 8px;
}

.msb .nb {
  padding-top: 5px;
  padding-left: 10px;
  margin-bottom: 30px;
  overflow: hidden;
}

ul.nv,
ul.ns {
  position: relative;
  padding: 0;
  list-style: none;
}

.nv li {
  display: block;
  position: relative;
}

.nv li::before {
  clear: both;
  content: "";
  display: table;
}

.nv li a {
  color: #444;
  padding: 10px 25px;
  display: block;
}

.nv li a .ic {
  font-size: 16px;
  margin-right: 5px;
  font-weight: 300;
  display: inline-block;
}

.nv .ns li a {
  padding: 10px 50px;
}

/*main content wrapper*/
.mcw {
  margin-left: 500px;
  position: relative;
  min-height: 100%;
  /*content view*/
}

/*globals*/
a,
a:focus,
a:hover {
  text-decoration: none;
}

.inbox .container-fluid {
  padding-left: 0;
  padding-right: 0;
}

.inbox ul,
.inbox li {
  margin: 0;
  padding: 0;
}

.inbox ul li {
  list-style: none;
}

.inbox ul li a {
  display: block;
  padding: 10px 20px;
}

.msb,
.mnb {
  -moz-animation: slidein 300ms forwards;
  -o-animation: slidein 300ms forwards;
  -webkit-animation: slidein 300ms forwards;
  animation: slidein 300ms forwards;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.mcw {
  -moz-animation: bodyslidein 300ms forwards;
  -o-animation: bodyslidein 300ms forwards;
  -webkit-animation: bodyslidein 300ms forwards;
  animation: bodyslidein 300ms forwards;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

body.msb-x .mcw,
body.msb-x .mnb {
  margin-left: 0;
  -moz-animation: bodyslideout 300ms forwards;
  -o-animation: bodyslideout 300ms forwards;
  -webkit-animation: bodyslideout 300ms forwards;
  animation: bodyslideout 300ms forwards;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

body.msb-x .msb {
  -moz-animation: slideout 300ms forwards;
  -o-animation: slideout 300ms forwards;
  -webkit-animation: slideout 300ms forwards;
  animation: slideout 300ms forwards;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

/* Slide in animation */
@-moz-keyframes slidein {
  0% {
    left: -500px;
  }

  100% {
    left: 0;
  }
}

@-webkit-keyframes slidein {
  0% {
    left: -500px;
  }

  100% {
    left: 0;
  }
}

@keyframes slidein {
  0% {
    left: -500px;
  }

  100% {
    left: 0;
  }
}

@-moz-keyframes slideout {
  0% {
    left: 0;
  }

  100% {
    left: -500px;
  }
}

@-webkit-keyframes slideout {
  0% {
    left: 0;
  }

  100% {
    left: -500px;
  }
}

@keyframes slideout {
  0% {
    left: 0;
  }

  100% {
    left: -500px;
  }
}

@-moz-keyframes bodyslidein {
  0% {
    left: 0;
  }

  100% {
    margin-left: 500px;
  }
}

@-webkit-keyframes bodyslidein {
  0% {
    left: 0;
  }

  100% {
    left: 0;
  }
}

@keyframes bodyslidein {
  0% {
    margin-left: 0;
  }

  100% {
    margin-left: 500px;
  }
}

@-moz-keyframes bodyslideout {
  0% {
    margin-left: 500px;
  }

  100% {
    margin-right: 0;
  }
}

@-webkit-keyframes bodyslideout {
  0% {
    margin-left: 500px;
  }

  100% {
    margin-left: 0;
  }
}

@keyframes bodyslideout {
  0% {
    margin-left: 500px;
  }

  100% {
    margin-left: 0;
  }
}

.dashboard-table-responsive .table th,
.table td {
  padding: 5px !important;
  vertical-align: middle;
}

td.hous img {
  border-radius: 7px;
  width: 50px;
}
.content {
  min-height: 308px;
  position: relative;
}
.sbtn{
  position: absolute;
    left: 0;
    right: 0;
    bottom: 30px;
}

@keyframes chartjs-render-animation {
  from {
    opacity: .99
  }

  to {
    opacity: 1
  }
}

.chartjs-render-monitor {
  animation: chartjs-render-animation 1ms
}

.chartjs-size-monitor,
.chartjs-size-monitor-expand,
.chartjs-size-monitor-shrink {
  position: absolute;
  direction: ltr;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  pointer-events: none;
  visibility: hidden;
  z-index: -1
}

.chartjs-size-monitor-expand>div {
  position: absolute;
  width: 1000000px;
  height: 1000000px;
  left: 0;
  top: 0
}

.chartjs-size-monitor-shrink>div {
  position: absolute;
  width: 200%;
  height: 200%;
  left: 0;
  top: 0
}

p.heigh img {
  height: 450px;
  width: auto;
}

/* div#target img {
  width: 100%;

} */



.price-input-container {
  width: 80%;
  float: right;
}

.price-input .price-field {
  display: flex;
  margin-bottom: 22px;
}

.price-field span {
  margin-right: 10px;
  margin-top: 6px;
  font-size: 17px;
}

.price-field input {
  flex: 1;
  height: 35px;
  font-size: 15px;
  font-family: "DM Sans", sans-serif;
  border-radius: 9px;
  text-align: center;
  border: 0px;
  background: #e4e4e4;
}

.price-input {
  width: 100%;
  font-size: 19px;
  color: #555;
}

.fmobile .navigation {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  /* min-width: 540px; */
  min-height: 60px;
  border-radius: 30px;
  background: #2f363e;
  box-shadow: 25px 25px 75px rgba(0, 0, 0, 0.25),
    10px 10px 70px rgba(0, 0, 0, 0.25),
    inset 5px 5px 10px rgba(0, 0, 0, 0.5),
    inset 5px 5px 20px rgba(255, 255, 255, 0.2),
    inset -5px -5px 15px rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.navigation li {
  position: relative;
  list-style: none;
  width: 80px;
  margin: 0 5px;
}

.navigation li::before {
  content: "";
  position: absolute;
  top: 35px;
  left: 50%;
  transform: translateX(-50%);
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #222;
  transition: 0.5s;
}

.navigation li.active::before {
  background: #ff4463;
  box-shadow: 0 0 5px #ff4463, 0 0 10px #ff4463, 0 0 20px #ff4463,
    0 0 30px #ff4463, 0 0 40px #ff4463, 0 0 50px #ff4463;
}

.navigation li a {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-decoration: none;
}

.navigation li a .icon {
  position: absolute;
  font-size: 1.75em;
  width: 80px;
  height: 80px;
  display: flex;
  color: #aaa;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
  transition-delay: 0.2s;
}

.navigation li.active a .icon {
  background: #ff4463;
  color: #c6c6c6;
  box-shadow: 5px 5px 7px rgba(0, 0, 0, 0.25),
    inset 2px 2px 3px rgba(255, 255, 255, 0.25),
    inset -3px -3px 5px rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  transition-delay: 0s;
}

.navigation li.active a .icon i {
  z-index: 22;
}

.navigation li a .icon::before {
  content: "";
  position: absolute;
  inset: 10px;
  background: #2f363e;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5),
    inset 2px 2px 3px rgba(255, 255, 255, 0.25),
    inset -3px -3px 5px rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  transform: scale(0);
  transition: 0.5s;
}

.navigation li.active a .icon::before {
  transform: scale(1);
}

.navigation li a .text {
  position: absolute;
  font-size: 0.75em;
  opacity: 0;
  transform: translateY(20px);
  transition: 0.5s;
  padding: 2px 10px;
  background: #fff;
  border-radius: 15px;
  color: #2f363e;
  box-shadow: 5px 5px 7px rgba(0, 0, 0, 0.25),
    inset -3px -3px 5px rgba(0, 0, 0, 0.5);
  transition-delay: 0s;
}

.navigation li.active a .text {
  opacity: 1;
  transition-delay: 0.2s;
}

@media screen and (max-width: 668px) {
  .navigation {
    min-width: 100%;
  }

  .navigation li {
    width: 70px;
  }

  .navigation li a .icon {
    height: 60px;
    width: 60px;
    font-size: 19px;
  }
  .video {
    width: 100% !important;
    position: relative !important;
    top: 0 !important;
    left: 0 !important;
    transform: none !important;
    border-radius: 0px !important;
}
.banner .banner-slider-inner ,.item-bg img{
  border-radius:0px !important;
}
}

.css-1aquho2-MuiTabs-indicator {
  background-color: transparent !important;
}