@import url('https://fonts.googleapis.com/css?family=Roboto');

#customers {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

#customers td,
#customers th {
    border: 1px solid #ddd;
    padding: 8px;
}

#customers tr:nth-child(even) {
    background-color: #f2f2f2;
}

#customers tr:hover {
    background-color: #ddd;
}

#customers th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #04AA6D;
    color: white;
}

.ba-we-love-subscribers {
    width: 100%;
    height: 50px;
    background-color: #fff;
    border-radius: 1px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, .15);
    font-family: 'Roboto', sans-serif;
    /* text-align: center; */
    margin: 0 0 -20px 0;
    overflow: hidden;
    opacity: 0;
}

.ba-we-love-subscribers.open {
    height: 85vh;
    opacity: 1;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
}

.ba-we-love-subscribers.popup-ani {
    -webkit-transition: all .8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transition: all .8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.ba-we-love-subscribers h1 {
    font-size: 20px;
    color: #757575;
    padding: 25px 0;
    margin: 0;
    font-weight: 400;
    font-family: 'Roboto', sans-serif;

}

.ba-we-love-subscribers .love {
    width: 20px;
    height: 20px;
    background-position: 35px 84px;
    display: inline-block;
    margin: 0 6px;
    background-size: 62px;
}

.ba-we-love-subscribers .ba-logo {
    width: 65px;
    height: 25px;
    background-position: 0px;
    margin: 0 auto;
    opacity: .5;
    cursor: pointer;
}

.ba-we-love-subscribers .ba-logo:hover {
    opacity: 1;
}

.logo-ani {
    transition: 0.5s linear;
    -webkit-transition: 0.5s linear;
}

.ba-we-love-subscribers input {
    font-size: 14px;
    padding: 12px 15px;
    border-radius: 15px;
    border: 0;
    outline: none;
    margin: 8px 0;
    width: 100%;
    box-sizing: border-box;
    line-height: normal;
    /*Bootstrap Overide*/
    font-family: sans-serif;
    /*Bootstrap Overide*/
}

.ba-we-love-subscribers form {
    padding: 5px 30px 0;
    margin-bottom: 15px;
}

.ba-we-love-subscribers input[name="email"] {
    background-color: #eee;
}

.ba-we-love-subscribers input[name="submit"] {
    background-color: #00aeef;
    cursor: pointer;
    color: #fff;
}

.ba-we-love-subscribers input[name="submit"]:hover {
    background-color: #26baf1;
}

.ba-we-love-subscribers .img {
    background-image: url("https://4.bp.blogspot.com/-1J75Et4_5vc/WAYhWRVuMiI/AAAAAAAAArE/gwa-mdtq0NIqOrlVvpLAqdPTV4VAahMsQCPcB/s1600/barrel-we-love-subscribers-img.png");
}

.ba-we-love-subscribers-fab {
    /* width: 80px;
    height: 80px;
    background-color: #ffffff;
    border-radius: 100px; */
    float: right;
    /* box-shadow: 0px 12px 45px rgba(0, 0, 0, .3);
    z-index: 5;
    position: relative;
    border: 2px solid #fff;
    cursor: pointer; */

}

.ba-we-love-subscribers-fab .img-fab {
    height: 36px;
    width: 36px;
    margin: 0px auto;
    background-image: url('../../images/ai.gif');
    background-position: center center;
    background-size: cover;
    
}

.ba-we-love-subscribers-fab .wrap {
    transform: rotate(0deg);
    -webkit-transition: all .15s cubic-bezier(0.15, 0.87, 0.45, 1.23);
    transition: all .15s cubic-bezier(0.15, 0.87, 0.45, 1.23);
}

.ba-we-love-subscribers-fab .ani {
    transform: rotate(45deg);
    -webkit-transition: all .15s cubic-bezier(0.15, 0.87, 0.45, 1.23);
    transition: all .15s cubic-bezier(0.15, 0.87, 0.45, 1.23);
}

.ba-we-love-subscribers-fab .close {
    background-position: 2px 1px;
    transform: rotate(-45deg);
    float: none;
    /*Bootstrap Overide*/
    opacity: 1;
    /*Bootstrap Overide*/
}

.close {
    background-color: #333;
    border-radius: 100px;
}

.ba-we-love-subscribers-wrap {
    position: fixed;
    right: 20px;
    bottom: 80px;
    z-index: 1000;
}

.ba-settings {
    position: absolute;
    top: -25px;
    right: 0px;
    padding: 10px 20px;
    background-color: #555;
    border-radius: 5px;
    color: #fff;
}

.ba-we-love-subscribers-wrap {
    width: 32%;
    max-width: 400px;
    min-width: 300px;
}

.tab-content img {
    width: 100%;
}