.phone {
  width: 100%;
  height: 260px;
  margin: auto;
  display: flex;
  align-items: flex-end;
  position: fixed;
  justify-content: center;
  bottom: 0px;
  z-index: 999;
}

.phone::before {
  content: '';
  position: absolute;
  width: 84%;
  height: 0px;
  bottom: -10px;
  box-shadow: 0 0 25px 9px rgba(255, 0, 0, 0.33), 50px 10px 25px 8px rgba(18, 255, 0, 0.33), -40px 8px 25px 9px rgba(242, 255, 0, 0.33);
  left: 0;
  right: 0;
  margin: auto;
}

.phone::after {
  content: '';
}

.phone_content {
  filter: contrast(20);
  width: 100%;
  background-color: white;
  overflow: hidden;
  position: absolute;
}

.phone_bottom {
  width: 100%;
  height: 76px;
  background: black;
  display: flex;
  justify-content: center;
  filter: blur(10px);
}

input {
  display: none;
}

label {
  cursor: pointer;
  display: flex;
  width: 33%;
  height: 66px;
  position: relative;
  z-index: 2;
  align-items: center;
  justify-content: center;
}

label>img {
  width: 50px;
  top: 0;
  bottom: 0;
  margin: auto;
  position: absolute;
  z-index: 3;
  transition: 200ms 100ms cubic-bezier(0.14, -0.08, 0.74, 1.4);
}

label::before {
  content: '';
  position: absolute;
}

.circle {
  width: 70px;
  height: 70px;
  background: black;
  position: absolute;
  top: 133px;
  z-index: 1;
  border-radius: 50%;
  left: 0;
  right: 0;
  margin: auto;
  transition: 200ms cubic-bezier(0.14, -0.08, 0.74, 1.4);
  box-shadow: 0px 82px 20px 0px rgba(128, 128, 128, 0.29);
}

.indicator {
  width: 80px;
  height: 70px;
  background-image: linear-gradient(0deg, #f7b0b0, rgba(183, 255, 154, 0)), linear-gradient(0deg, rgba(158, 255, 151, 0.75), rgba(183, 255, 154, 0)), linear-gradient(0deg, #b4fffb, rgba(183, 255, 154, 0));
  background-size: cover;
  background-position: 0 10px;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: -42px;
  right: 0;
  margin: auto;
  transition: 200ms cubic-bezier(0.14, -0.08, 0.74, 1.4);
}

#s1:checked~[for="s1"]>img {
  top: -95px;
}

#s1:checked~.circle,
#s1:checked~div div .indicator {
  left: -66%;
}

#s2:checked~[for="s2"]>img {
  top: -100px;
}

#s2:checked~.circle,
#s2:checked~div div .indicator {
  left: 0;
}

#s3:checked~[for="s3"]>img {
  top: -106px;
}

#s3:checked~.circle,
#s3:checked~div div .indicator {
  left: 66%;
}

.w-full {
  width: 100% !important;
}

.tab-content {
  padding: 10px 0px !important;
}

.demo {
  padding: 0px;
  min-height: 280px;
}

.tab-content {
  padding: 10px;
}

.listing {
  height: 80vh;
  overflow-y: scroll;
  margin-top: 10px;
}

.maintab {
  height: 70vh;
  overflow-y: scroll;
}

ul.san li {
  margin-left: 10px;
  margin-right: 10px;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
}

@nav-link-hover-bg: #eeeeee;
@nav-tabs-border-color: #dddddd;
@border-radius-base: 5px;
@screen-xs-max: 767px;