.main-header {
    transition: all 0.3s ease;
}

.home-header {
    /* styles for home page header */
}

.inner-header {
    /* styles for inner pages header */
}
