.map-card {
    position: relative;
    height: 100%;
    width: 100%;
}

.map-container {
    height: 100%;
    width: 100%;
    min-height: 400px;
    /* Ensure minimum height for mobile view */
}

/* Additional styles for mobile view */
@media (max-width: 768px) {
    .map-container {
        height: 80vh;
        /* Adjust height for mobile view */
    }
}