.form-subscribe .form-control::placeholder {
    color: #ffffff;
    opacity: 1;
    /* Firefox */
}

.form-subscribe .form-control::-ms-input-placeholder {
    /* Edge 12 -18 */
    color: #ffffff;
}

.form-subscribe {
    max-width: 600px;
    margin: 0 auto
}

.form-subscribe .form-control {
    background-color: #f0151f;
    padding-left: 24px;
    padding-right: 24px;
    letter-spacing: 1px;
    border: none;
    border-top-left-radius: 36px;
    border-bottom-left-radius: 36px;
    color: #ffffff;
}

.form-subscribe .form-control.focus,
.form-subscribe .form-control:focus {
    z-index: 2;
    background-color: #f0151f;
}

.form-subscribe .btn {
    border-top-right-radius: 36px;
    border-bottom-right-radius: 36px;
    background: #191f2b;
    border-color: #191f2b;
    height: 46.5px;
}