.addressAutoComplete {
    margin-top: 20px;
    margin-bottom: 20px;
}

.MuiAutocomplete-inputRoot {
    background-color: #f5f5f5;
    border-radius: 4px;
}

.MuiAutocomplete-listbox {
    max-height: 300px;
    overflow-y: auto;
}

.MuiAutocomplete-option {
    padding: 10px;
    border-bottom: 1px solid #e0e0e0;
}

.MuiAutocomplete-option:hover {
    background-color: #e8f0fe;
}
